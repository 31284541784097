<template>
  <reports :pageOptions="pageOptions"></reports>
</template>

<script>
import Reports from "@/components/count/reports/Reports";

export default {
  name: "MinAverageMax",
  components: {
    Reports
  },
  data() {
    return {
      pageOptions: {
        pageTitle: "min-average-max",
        searchRouteName: "r_count-min-average-max-search-filters",
        helpLinkName: "r_count-min-average-max-help-online",
        helpSlug: "count-min-average-max",
        filtersSection: "min_max_average",
        barWidth: 100,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false
            },
            x: {
              stacked: true
            }
          }
        },
        chartOptionsY: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                callback: function(value) {
                  return value;
                }
              }
            }
          }
        },
        legendLabels: ["maximum-value", "average-value", "minimum-value"]
      }
    };
  }
};
</script>
